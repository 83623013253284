<template>
  <div>
  <calc-page>
    <template slot="topBox">
      <round-add-container
        title="添加各年缴费记录"
        :list="feeList"
        @add="onAdd"
      >
        <annuity-year-list
          :list="feeList"
          @itemClick="onUpdate"
        ></annuity-year-list>
      </round-add-container>
    </template>

    <template slot="footerBtn">
      <div
        v-bind:class="['btn-box', !btnDisabled ? 'btn-bk' : '']"
        @click="calc"
      >
        开始计算
      </div>
    </template>
  </calc-page>
  <advanced-dialog ref="overlay"></advanced-dialog>
  </div>
</template>

<script>
import CalcPage from "@/components/CalcPage";
import RoundAddContainer from "@/views/calc/components/RoundAddContainer.vue";
import AnnuityYearList from "@/views/calc/annuity/components/AnnuityYearList.vue";
import AdvancedDialog from "./AdvancedDialog";

// import BasicAnnuityAdvancedItem from './BasicAnnuityAdvancedItem'
import { mapGetters } from "vuex";
import { siCalc } from "@/api/tool";
import to from "@/utils/to";
export default {
  components: {
    CalcPage,
    RoundAddContainer,
    AnnuityYearList,
    AdvancedDialog,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    feeList() {
      return this.getAnnuityBasic().feeList;
    },
    btnDisabled() {
      if (this.feeList.length > 0) {
        return false;
      }

      return true;
    },
  },
  methods: {
    onAdd() {
      const item = {
        idx: new Date().getTime(),
        year: "",
        payment: "",
        month: "",
      };
      this.$refs.overlay.open(item);
      // this.$router.push({
      //   name: "BasicAnnuityAdvancedDetails",
      //   query: {
      //     item,
      //   },
      // });
    },
    onUpdate(item) {
      this.$refs.overlay.open(JSON.parse(JSON.stringify(item)));
    },
    async calc() {
      let sumMonth = 0;
      this.feeList.forEach(function (item) {
        sumMonth = parseInt(sumMonth) + parseInt(item.month);
      });
      if (sumMonth < 180) {
        this.$toast.fail("缴费未满180个月，请继续填写");
        return;
      }

      const v = this.getAnnuityBasic();
      console.log(v)
      const {
        sex,
        age,
        firstWorkTime,
        retireDate,
        paymentYearNum,
        averageWage,
        feeList
      } = v

      let data = {
        sex,
        age,
        firstWorkTime: firstWorkTime + '/01',
        retireDate: retireDate + '/01',
        paymentYearNum,
        averageWage,
        feeList
      }
      this.loading = true;
      let [err, res] = await to(siCalc(data));
      this.loading = false;

      if (err) {
        this.$toast.fail("计算失败");
        return;
      }
      this.$toast.success("计算成功");
      // this.setResult(res);

      this.$router.push({
        name: "BasicAnnuityCalcResultPage",
        query: {
          isShow: false,
          res: JSON.stringify(res),
        },
      });
    },
    ...mapGetters("calc", ["getAnnuityBasic"]),
  },
};
</script>

<style scoped>
.top-btn {
  height: 50px;
  background: #dbf0ff;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  color: #658CF1;
}

.img-box {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.center {
  min-height: 80px;
}

.center >>> .van-divider {
  margin: 0;
}

.row-box {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333333;
}

.time-box {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333333;
  align-items: center;
}

.time-box span {
  width: 16px;
  height: 2px;
  background: #999999;
  border-radius: 1px;
  margin: 0 4px;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 134px;
}

.btn-bk {
  background: #658CF1;
}
</style>